import { AxiosInstance } from "axios";
import dayjs from "dayjs";

import {
  convertTokenCountToWordCount,
  convertWordCountToTokenCount,
} from "app/core/klever/feedback/source-utils";
import { FeedbackCommentsData, FeedbackThreadsData } from "app/core/klever/feedback/types";

import {
  ZAPIER_DELIGHTED_TEMPLATE_URL,
  ZAPIER_DISCORD_TEMPLATE_URL,
  ZAPIER_DOVETAIL_TEMPLATE_URL,
  ZAPIER_FATHOM_TEMPLATE_URL,
  ZAPIER_FIREFLIES_TEMPLATE_URL,
  ZAPIER_FRONT_TEMPLATE_URL,
  ZAPIER_GONG_TEMPLATE_URL,
  ZAPIER_HELPSCOUT_TEMPLATE_URL,
  ZAPIER_HUBSPOT_TEMPLATE_URL,
  ZAPIER_INTERCOM_TEMPLATE_URL,
  ZAPIER_SLACK_TEMPLATE_URL,
  ZAPIER_ZENDESK_TEMPLATE_URL,
} from "app/product-research/components/common/zapier";

import { ProcessStatusType } from "app/contexts/ProcessStatusContext";

import { AnalysisItemClient } from "app/analysis-item/AnalysisItemClient";
import { ProjectCategoryClient } from "app/categories/CategoryClient";
import { ChatClient } from "app/chat/ChatClient";
import { FeedbackClient } from "app/feedback/FeedbackClient";
import { ProjectClient } from "app/projects/ProjectClient";
import { Project } from "app/projects/types/models";
import { SourceClient } from "app/sources/SourceClient";
import { OpenSurveyClient } from "app/surveys-public/OpenSurveyClient";
import { SurveyClient } from "app/surveys/SurveyClient";
import { GetOrgReportReturn } from "app/team/types/models";
import { TrendsClient } from "app/trends/TrendsClient";
import { UserStoryClient } from "app/user-stories/UserStoryClient";
import { UserStory } from "app/user-stories/types/responseTypes";

import {
  SourceIntegrationServiceSchema,
  SourceOriginServiceSchema,
} from "../../app/sources/types/shared";
import { createAxiosInstance } from "../../app/utils/http";
import { AnalysisTermType } from "../../app/utils/schemas";

import {
  AnalysisItemsData,
  AnalysisItemStatus,
  FeedbackAnalysisItem,
  FeedbackSource,
  FeedbackSourceType,
  Item,
  PaginatedTranscriptSnippetResponse,
  TranscriptSnippetResponse,
  UpdateMentionResponse,
} from "./feedback-api-v2-schema";
import { OrganizationSettings, OrganizationUsage } from "./organization-api-v1-schema";
import { ProductApiV1 } from "./product-api-v1-schema";

// Response Types
export type AppStoreType = "ios" | "google_play";
export type ReviewSentiment = "positive" | "negative" | "neutral";

export type Review = {
  date: string;
  author: string;
  stars: number;
  title: string;
  body: string;
  timestamp: string;
  id: string;
  country?: string;
  deals?: string;
  surveyResponseId?: number;
};

export type AppSearchResult = {
  id: string;
  storeType: AppStoreType;
  name: string;
  icon: string;
};

export type SummaryTermLite = {
  id: string;
  type: string;
  rank: number;
  term: string; // text
  //numberOfThreads: number
  threads?: { extra: Record<string, unknown> }[];
  mentions: number;
  reviewsCount: number;
  updatedTerm: string;
  //updatedText: string
  deletedAt: Date;
  sortOrder: number;
  projects?: Omit<Project, "sort" | "description">[];
  userStories: Array<UserStory>;
};

export type SummaryTerm = {
  id: string;
  term: string;
  dateStart: Date;
  dateEnd: Date;
  mentions: number;
  reviews: Array<Review>;
};

export type AppSummaryData = AppSearchResult & {
  ratings: {
    averageStars: number;
    total: number;
  };

  reviews: {
    sentimentSummary: {
      score: number | null;
      total: number;
    };
    sentimentTimeline: Array<{
      date: string;
      positive: number;
      negative: number;
      neutral: number;
    }>;
    recent: Array<
      Review & {
        sentiment: ReviewSentiment | null;
      }
    >;
    complaints: Array<SummaryTermLite>;
    requests: Array<SummaryTermLite>;
    loved: Array<SummaryTermLite>;
    competitors: Array<SummaryTermLite>;
    summary: string;
    numberOfThreads: number;
    numberOfAnalysedReviews: number;
  };
};

export type AppSummaryDataResponse = {
  data: Array<AppSummaryData>;
};

export type AppsResponse = {
  data: Array<{
    id: string;
    name: string;
  }>;
};

export type AppDownloadOpts = { download1Year?: boolean };

export class KlassyClient
  implements
    OpenSurveyClient,
    SurveyClient,
    ProjectClient,
    UserStoryClient,
    FeedbackClient,
    TrendsClient,
    ChatClient
{
  api: AxiosInstance;

  constructor({ baseURL, token }: { baseURL: string; token: string }) {
    this.api = createAxiosInstance({
      baseURL,

      headers: {
        Authorization: token,
      },
    });
  }

  async getSearchResults({
    orgSlug,
    query,
    storeType,
    country,
  }: {
    orgSlug: string;
    query: string;
    storeType: AppStoreType;
    country: string;
  }): Promise<Array<AppSearchResult>> {
    // Migration: New endpoint will return `data` but we still need to expect a response from the old one until
    // migration is complete.
    const results = await this.api.get<AppSearchResult[] | { data: AppSearchResult[] }>(
      "/app/v1/search",
      {
        params: {
          orgSlug,
          q: query,
          s: storeType,
          country,
        },
      }
    );

    return Array.isArray(results.data) ? results.data : results.data.data;
  }

  async getAppData({
    orgSlug,
    appIds,
    startDate,
  }: {
    orgSlug: string;
    appIds: string[];
    startDate: Date;
  }): Promise<Array<AppSummaryData>> {
    const results = await this.api.get<AppSummaryDataResponse>("/app/v1/summaries", {
      params: {
        orgSlug,
        ids: appIds.join(","),
        startDate: dayjs(startDate).toISOString().substring(0, 10),
      },
    });

    return results.data.data;
  }

  async getSummaryTerm({
    orgSlug,
    id,
    type,
  }: {
    orgSlug: string;
    id: string;
    type: AnalysisTermType;
  }): Promise<SummaryTerm> {
    const results = await this.api.get<{ data: SummaryTerm }>(
      `/app/v1/summaryTerms/${type}/${id}`,
      {
        params: {
          // This is just for request attribution. It's logged and helps to see all org related requests.
          orgSlug,
        },
      }
    );

    return results.data.data;
  }

  async getAppNames(orgSlug: string, ids: string[]): Promise<Record<string, string>> {
    const names: Record<string, string> = {};
    const apps = await this.getApps(orgSlug, ids);
    for (const app of apps) {
      names[app.id] = app.name;
    }

    return names;
  }

  private async getApps(orgSlug: string, ids: string[]): Promise<AppsResponse["data"]> {
    if (ids.length === 0) return [];

    const result = await this.api.get<AppsResponse>("/app/v1/apps", {
      params: {
        orgSlug,
        ids: ids.join(","),
      },
    });

    return result.data.data;
  }

  async getProduct(productSlug: string): Promise<ProductApiV1.ProductFull | undefined> {
    const results = await this.api.get<{ data: ProductApiV1.ProductFull }>(
      `/product/v1/products/${productSlug}`
    );

    return results.data.data;
  }

  async updateExcludedSourceAuthors(args: {
    feedbackSourceId: number;
    excludedAuthors: string[];
  }): Promise<{ excludedAuthors: string[] }> {
    const res = await this.api.put<{ data: { excludedAuthors: string[] } }>(
      `/feedback/v2/sources/${args.feedbackSourceId}/excluded-authors`,
      { excludedAuthors: args.excludedAuthors }
    );
    return res.data.data;
  }

  async analyzeOlderFeedbackSource(args: { id: number; orgSlug: string }): Promise<FeedbackSource> {
    const results = await this.api.post<{ data: FeedbackSource }>(
      `/feedback/v2/sources/${args.id}/analyze-older-feedback`,
      args
    );

    return results.data.data;
  }

  async appendFeedbackToExistingSource(args: {
    sourceId: number;
    orgSlug: string;
    text: string;
    rowCount: number;
  }): Promise<FeedbackSource> {
    const results = await this.api.post<{ data: FeedbackSource }>(
      `/feedback/v2/sources/${args.sourceId}/analyze-older-feedback`,
      args
    );

    return results.data.data;
  }

  /** @deprecated Use listSources instead */
  async getFeedbackSources(args: {
    orgSlug: string;
    type?: FeedbackSourceType;
    includeCount?: boolean;
  }): Promise<Array<FeedbackSource>> {
    const results = await this.api.get<{ data: Array<FeedbackSource> }>("/feedback/v2/sources", {
      params: args,
    });

    // Klassy's "app" type is mapped to "appNew".
    // This is temporary while we transition from the legacy "app" type.
    // Once all customers are migrated the legacy "app" will be removed and "appNew" will be renamed to "app".
    return results.data.data.map((feedbackSource) => {
      let type = feedbackSource.type;
      // @ts-ignore ignore warning about "app" not being in FeedbackSourceType.
      if (feedbackSource.type === "app") {
        type = FeedbackSourceType.appNew;
      }
      // @ts-ignore ignore warning about "apify" not being in FeedbackSourceType.
      else if (feedbackSource.type === "apify") {
        type = feedbackSource.extra?.apifyType as FeedbackSourceType;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_HUBSPOT_TEMPLATE_URL ||
          feedbackSource.originService === "HUBSPOT")
      ) {
        type = FeedbackSourceType.hubspot;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_DELIGHTED_TEMPLATE_URL ||
          feedbackSource.originService === "DELIGHTED")
      ) {
        type = FeedbackSourceType.delighted;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_ZENDESK_TEMPLATE_URL ||
          feedbackSource.originService === "ZENDESK")
      ) {
        type = FeedbackSourceType.zapier_zendesk;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_INTERCOM_TEMPLATE_URL ||
          feedbackSource.originService === "INTERCOM")
      ) {
        type = FeedbackSourceType.zapier_intercom;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_DISCORD_TEMPLATE_URL ||
          feedbackSource.originService === "DISCORD")
      ) {
        type = FeedbackSourceType.discord;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_DOVETAIL_TEMPLATE_URL ||
          feedbackSource.originService === "DOVETAIL")
      ) {
        type = FeedbackSourceType.dovetail;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_FIREFLIES_TEMPLATE_URL ||
          feedbackSource.originService === "FIREFLIES")
      ) {
        type = FeedbackSourceType.fireflies;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_FATHOM_TEMPLATE_URL ||
          feedbackSource.originService === "FATHOM")
      ) {
        type = FeedbackSourceType.fathom;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.extra?.templateUrl === ZAPIER_GONG_TEMPLATE_URL
      ) {
        type = FeedbackSourceType.gongCalls;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_FRONT_TEMPLATE_URL ||
          feedbackSource.originService === "FRONT")
      ) {
        type = FeedbackSourceType.front;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        (feedbackSource.extra?.templateUrl === ZAPIER_HELPSCOUT_TEMPLATE_URL ||
          feedbackSource.originService === "HELP_SCOUT")
      ) {
        type = FeedbackSourceType.helpscout;
      } else if (
        (feedbackSource.type === FeedbackSourceType.zapier &&
          (feedbackSource.extra?.templateUrl === ZAPIER_SLACK_TEMPLATE_URL ||
            feedbackSource.originService === "SLACK")) ||
        (feedbackSource.integrationService === SourceIntegrationServiceSchema.enum.FIVETRAN &&
          feedbackSource.originService === SourceOriginServiceSchema.enum.SLACK)
      ) {
        type = FeedbackSourceType.slack;
      } else if (
        feedbackSource.type === FeedbackSourceType.freeFormText &&
        (feedbackSource.extra?.ingestionType === "zoom" || feedbackSource.originService === "ZOOM")
      ) {
        type = FeedbackSourceType.zoom;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "SALESFORCE"
      ) {
        type = FeedbackSourceType.salesforce;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "GOOGLESHEET"
      ) {
        type = FeedbackSourceType.googlesheet;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "CANNY"
      ) {
        type = FeedbackSourceType.canny;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "EXCEL"
      ) {
        type = FeedbackSourceType.excel;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "CRISP"
      ) {
        type = FeedbackSourceType.crisp;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "NOTION"
      ) {
        type = FeedbackSourceType.notion;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "CLICKUP"
      ) {
        type = FeedbackSourceType.clickup;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "REDDIT"
      ) {
        type = FeedbackSourceType.reddit;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "HOTJAR"
      ) {
        type = FeedbackSourceType.hotjar;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "SURVEYMONKEY"
      ) {
        type = FeedbackSourceType.surveymonkey;
      } else if (
        feedbackSource.type === FeedbackSourceType.zapier &&
        feedbackSource.originService === "TYPEFORM"
      ) {
        type = FeedbackSourceType.typeform;
      } else if (
        feedbackSource.integrationService === SourceIntegrationServiceSchema.enum.FIVETRAN &&
        feedbackSource.originService === SourceOriginServiceSchema.enum.HUBSPOT
      ) {
        type = FeedbackSourceType.hubspot;
      } else if (
        feedbackSource.integrationService === SourceIntegrationServiceSchema.enum.FIVETRAN &&
        feedbackSource.originService === SourceOriginServiceSchema.enum.GONG
      ) {
        type = FeedbackSourceType.gongCalls;
      } else if (
        feedbackSource.integrationService === SourceIntegrationServiceSchema.enum.FIVETRAN &&
        feedbackSource.originService === SourceOriginServiceSchema.enum.ZENDESK
      ) {
        type = FeedbackSourceType.zendesk;
      } else if (
        feedbackSource.integrationService === SourceIntegrationServiceSchema.enum.FIVETRAN &&
        feedbackSource.originService === SourceOriginServiceSchema.enum.INTERCOM
      ) {
        type = FeedbackSourceType.intercom;
      } else if (
        feedbackSource.integrationService === SourceIntegrationServiceSchema.enum.FIVETRAN &&
        feedbackSource.originService === SourceOriginServiceSchema.enum.SALESFORCE_SERVICE
      ) {
        type = FeedbackSourceType.salesforce_service;
      } else if (
        feedbackSource.integrationService === SourceIntegrationServiceSchema.enum.APIFY &&
        feedbackSource.originService === SourceOriginServiceSchema.enum.AMAZON
      ) {
        type = FeedbackSourceType.amazon;
      } else if (
        feedbackSource.integrationService === SourceIntegrationServiceSchema.enum.APIFY &&
        feedbackSource.originService === SourceOriginServiceSchema.enum.TWITTER
      ) {
        type = FeedbackSourceType.twitter;
      } else if (
        feedbackSource.integrationService === SourceIntegrationServiceSchema.enum.APIFY &&
        feedbackSource.originService === SourceOriginServiceSchema.enum.DISCORD
      ) {
        type = FeedbackSourceType.discord;
      }

      return {
        ...feedbackSource,
        // @ts-ignore ignore warning about "app" not being in FeedbackSourceType.
        type,
      };
    });
  }

  async getFeedbackAnalysisItems(args: {
    orgSlug: string;
    feedbackSourceId: number;
    dateStart?: string | Date;
    dateEnd?: string | Date;
    type?: string;
  }): Promise<AnalysisItemsData> {
    const results = await this.api.get<{ data: AnalysisItemsData }>("/feedback/v2/analysisItems", {
      params: args,
    });

    return results.data.data;
  }

  async getFeedbackAnalysisItemsStatus(args: {
    orgSlug: string;
    feedbackSourceId: string;
  }): Promise<Array<AnalysisItemStatus>> {
    const results = await this.api.get<{ data: Array<AnalysisItemStatus> }>(
      "/feedback/v2/dateRange",
      {
        params: args,
      }
    );

    return results.data.data.map(({ dateStart, dateEnd, ...others }) => ({
      ...others,
      dateStart: new Date(dateStart),
      dateEnd: new Date(dateEnd),
    }));
  }

  async getFeedbackAnalysisItem(args: {
    orgSlug: string;
    feedbackAnalysisItemid: string;
  }): Promise<FeedbackAnalysisItem> {
    const results = await this.api.get<{ data: FeedbackAnalysisItem }>(
      `/feedback/v2/analysisItems/${args.feedbackAnalysisItemid}`,
      {
        params: {
          orgSlug: args.orgSlug,
        },
      }
    );

    return results.data.data;
  }

  async updateFeedbackAnalysisItem(args: {
    orgSlug: string;
    id: number;
    updatedText?: string;
    isDeleted?: boolean;
  }): Promise<FeedbackAnalysisItem> {
    const results = await this.api.post<{ data: FeedbackAnalysisItem }>(
      `/feedback/v2/analysisItems/${args.id}`,
      {
        orgSlug: args.orgSlug,
        updatedText: args.updatedText,
        isDeleted: args.isDeleted,
      }
    );
    return results.data.data;
  }

  async mergeFeedbackAnalysisItem(args: {
    orgSlug: string;
    targetId: number;
    mergeIds: number[];
  }): Promise<boolean> {
    const results = await this.api.post(`/feedback/v2/mergeAnalysisItems`, {
      orgSlug: args.orgSlug,
      mergeIds: [args.targetId, ...args.mergeIds],
    });

    return results.status === 200;
  }

  async sortFeedbackAnalysisItem(args: {
    orgSlug: string;
    analysisItemIds: Array<number>;
  }): Promise<FeedbackAnalysisItem> {
    const results = await this.api.post<{ updatedItems: FeedbackAnalysisItem }>(
      `/feedback/v2/sortAnalysisItems`,
      {
        orgSlug: args.orgSlug,
        analysisItemIds: args.analysisItemIds,
      }
    );
    const data = results.data.updatedItems;
    return data;
  }

  async updateMentionsForAnalysisItem(args: {
    orgSlug: string;
    sourceAnalysisItemId: number;
    commentId: string;
    targetAnalysisItemId?: number;
  }): Promise<UpdateMentionResponse> {
    const results = await this.api.put<UpdateMentionResponse>(
      `/feedback/v2/mentions/${args.commentId}`,
      {
        orgSlug: args.orgSlug,
        analysisItemSource: args.sourceAnalysisItemId,
        analysisItemTarget: args.targetAnalysisItemId,
      }
    );

    return results.data;
  }

  async getFeedbackComments(args: {
    orgSlug: string;
    feedbackSourceId: string;
    page?: number;
    pageSize?: number;
    sort: "asc" | "desc";
    dateStart?: string | Date;
    dateEnd?: string | Date;
  }): Promise<FeedbackCommentsData> {
    const results = await this.api.get<{ data: FeedbackCommentsData }>(
      `/feedback/v2/sources/${args.feedbackSourceId}/comments`,
      {
        params: {
          ...args,
        },
      }
    );

    return results.data.data;
  }

  async getFeedbackThreads(args: {
    orgSlug: string;
    feedbackSourceId: string;
    page?: number;
    pageSize?: number;
    sort: "asc" | "desc";
    dateStart?: string | Date;
    dateEnd?: string | Date;
  }): Promise<FeedbackThreadsData> {
    const results = await this.api.get<{ data: FeedbackThreadsData }>(
      `/feedback/v2/sources/${args.feedbackSourceId}/threads`,
      {
        params: {
          ...args,
        },
      }
    );

    return results.data.data;
  }

  async createOrganizationSettings({
    orgSlug,
    threadLimit,
    questionLimit,
    optedIntoOverage,
    limitAnchorDate,
    wordLimit,
    isPaying,
  }: Pick<
    OrganizationSettings,
    | "orgSlug"
    | "questionLimit"
    | "threadLimit"
    | "wordLimit"
    | "optedIntoOverage"
    | "limitAnchorDate"
  > & { isPaying: boolean }): Promise<OrganizationSettings> {
    const result = await this.api.post<{ data: OrganizationSettings }>(
      `/organization/v1/${orgSlug}/settings`,
      {
        orgSlug,
        threadLimit,
        tokenLimit: convertWordCountToTokenCount(wordLimit),
        questionLimit,
        optedIntoOverage,
        limitAnchorDate,
        isPaying,
      }
    );

    return result.data.data;
  }

  async getOrganizationSettings({ orgSlug }: { orgSlug: string }): Promise<OrganizationSettings> {
    const result = await this.api.get<{ data: OrganizationSettings }>(
      `/organization/v1/${orgSlug}/settings`
    );

    return result.data.data;
  }

  async updateOrganizationSettings({
    orgSlug,
    questionLimit,
    optedIntoOverage,
    limitAnchorDate,
    brands,
    language,
    wordLimit,
    isPaying,
    plan,
  }: {
    orgSlug: string;
    questionLimit?: OrganizationSettings["questionLimit"];
    limitAnchorDate?: OrganizationSettings["limitAnchorDate"];
    optedIntoOverage?: OrganizationSettings["optedIntoOverage"];
    brands?: string[];
    language?: string;
    wordLimit?: OrganizationSettings["wordLimit"];
    isPaying?: boolean;
    plan?: string;
  }): Promise<OrganizationSettings> {
    const result = await this.api.put<{ data: OrganizationSettings }>(
      `/organization/v1/${orgSlug}/settings`,
      {
        questionLimit,
        limitAnchorDate,
        optedIntoOverage,
        brands,
        language,
        ...(wordLimit ? { tokenLimit: convertWordCountToTokenCount(wordLimit) } : {}),
        isPaying,
        plan,
      }
    );

    return result.data.data;
  }

  async reactivateOrganization({
    orgSlug,
  }: {
    orgSlug: string;
  }): Promise<{ isStatusChanged: boolean; orgSettings?: OrganizationSettings }> {
    const res = await this.api.post<{ data: OrganizationSettings }>(
      `/organization/v1/${orgSlug}/reactivate`
    );

    if (res.status === 204) {
      return { isStatusChanged: false };
    }

    return {
      isStatusChanged: res.status === 200,
      orgSettings: res.data.data,
    };
  }

  async deactivateOrganizations({ orgSlugs }: { orgSlugs: string[] }): Promise<number> {
    const res = await this.api.post<{ data: { count: number } }>(`/organization/v1/deactivate`, {
      orgSlugs,
    });
    return res.data.data.count;
  }

  async getProcessStatus(args: { orgSlug: string; feedbackSourceIds: number[] }) {
    const result = await this.api.get<ProcessStatusType>(
      `/feedback/v2/${args.orgSlug}/process-status?feedbackSourceIds=${args.feedbackSourceIds.join(
        ","
      )}`
    );

    return result.data;
  }

  /**
   * Returns org lifetime usage stats.
   */
  async getOrganizationUsage({ orgSlug }: { orgSlug: string }): Promise<OrganizationUsage> {
    const result = await this.api.get<{ data: OrganizationUsage }>(
      `/organization/v1/${orgSlug}/usage`
    );

    if (result.data.data.sources) {
      Object.entries(result.data.data.sources).forEach(([key, value]) => {
        if (value) {
          result.data.data.sources[key] = convertTokenCountToWordCount(value) || 0;
        }
      });
    }

    if (result.data.data.settings) {
      result.data.data.settings.wordLimit =
        convertTokenCountToWordCount(result.data.data.settings.tokenLimit) || 0;
    }

    return result.data.data;
  }

  async getRecentAnalysis({
    orgSlug,
    stringMatch,
  }: {
    orgSlug: string;
    stringMatch?: string;
  }): Promise<FeedbackAnalysisItem[]> {
    const result = await this.api.get<FeedbackAnalysisItem[]>(`/feedback/v2/analysis/recent`, {
      params: {
        orgSlug,
        stringMatch,
      },
    });

    return result.data;
  }

  async getSourceCounts(orgSlug: string, feedbackSourceId: number) {
    const result = await this.api.get<{ comments: number; analysis: number }>(
      `/feedback/v2/sources/${feedbackSourceId}/counts`,
      {
        params: {
          orgSlug,
        },
      }
    );

    return result.data;
  }

  async getAnalysis({
    orgSlug,
    analysisItemIds,
  }: {
    orgSlug: string;
    analysisItemIds: number[];
  }): Promise<FeedbackAnalysisItem[]> {
    const result = await this.api.get<FeedbackAnalysisItem[]>(`/feedback/v2/analysis/`, {
      params: {
        orgSlug,
        analysisItemIds,
      },
    });

    return result.data;
  }

  async getOrgReport({ orgSlug }: { orgSlug: string }): Promise<GetOrgReportReturn> {
    const result = await this.api.get<GetOrgReportReturn>(`/organization/v1/${orgSlug}/report`);
    return result.data;
  }

  async analyzeTextType({
    orgSlug,
    content,
  }: {
    orgSlug: string;
    content: string;
  }): Promise<{ type: string }> {
    const result = await this.api.post<{ type: string }>(
      `/sources/analyze-text-type?orgSlug=${orgSlug}`,
      {
        content,
      }
    );
    return result.data;
  }

  async getCommentsAnchoredToItem(
    this: KlassyClient,
    args: GetCommentsAnchoredToItemRequest
  ): Promise<PaginatedTranscriptSnippetResponse<Item>> {
    const pageParam = args.page !== undefined ? `&page=${args.page}` : "";
    const url = `/feedback/v2/${args.orgSlug}/anchored-comments?anchorCommentId=${args.anchorCommentId}${pageParam}`;

    const res = await this.api.get<TranscriptSnippetResponse>(url);
    return res.data;
  }

  /* Surveys */
  listSurveys = SurveyClient.prototype.listSurveys;
  createSurvey = SurveyClient.prototype.createSurvey;
  getSurveyById = SurveyClient.prototype.getSurveyById;
  updateSurvey = SurveyClient.prototype.updateSurvey;
  patchSurvey = SurveyClient.prototype.patchSurvey;
  deleteSurvey = SurveyClient.prototype.deleteSurvey;
  sortSurvey = SurveyClient.prototype.sortSurvey;
  getSurveyAnswers = SurveyClient.prototype.getSurveyAnswers;
  updateUrlSlug = SurveyClient.prototype.updateUrlSlug;
  getSurveyAssociations = SurveyClient.prototype.getSurveyAssociations;
  getSurveyResponses = SurveyClient.prototype.getSurveyResponses;
  getSurveyCounts = SurveyClient.prototype.getSurveyCounts;
  cloneSurvey = SurveyClient.prototype.cloneSurvey;
  getSurveyFeedback = SurveyClient.prototype.getSurveyFeedback;
  getSurveyFeedbackSource = SurveyClient.prototype.getSurveyFeedbackSource;
  getSurveyAnalysisItem = SurveyClient.prototype.getSurveyAnalysisItem;
  deleteSurveyResponse = SurveyClient.prototype.deleteSurveyResponse;
  getSurveyResponse = SurveyClient.prototype.getSurveyResponse;
  getSurveyResponsesCounts = SurveyClient.prototype.getSurveyResponsesCounts;
  getSurveyAnswerStatistics = SurveyClient.prototype.getSurveyAnswerStatistics;
  getSurveyResponsesByQuestionId = SurveyClient.prototype.getSurveyResponsesByQuestionId;
  exportSurveyResponsesByQuestionId = SurveyClient.prototype.exportSurveyResponsesByQuestionId;

  /* Public/Open Surveys */
  getOpenSurvey = OpenSurveyClient.prototype.getOpenSurvey;
  postSurveyAnswers = OpenSurveyClient.prototype.postSurveyAnswers;
  getSurveyStats = OpenSurveyClient.prototype.getSurveyStats;
  getAutogeneratedQuestions = OpenSurveyClient.prototype.getAutogeneratedQuestions;
  askNewQuestion = OpenSurveyClient.prototype.askNewQuestion;
  postAutogeneratedAnswer = OpenSurveyClient.prototype.postAutogeneratedAnswer;
  /* Interviews */
  getInterviewAnalysis = SurveyClient.prototype.getInterviewAnalysis;
  getInterviewRawData = SurveyClient.prototype.getInterviewRawData;
  /* Projects */
  listProjects = ProjectClient.prototype.listProjects;
  sortProjects = ProjectClient.prototype.sortProjects;
  createProject = ProjectClient.prototype.createProject;
  deleteProject = ProjectClient.prototype.deleteProject;
  getProject = ProjectClient.prototype.getProject;
  getProjectItems = ProjectClient.prototype.getProjectItems;
  removeItem = ProjectClient.prototype.removeItem;
  createItem = ProjectClient.prototype.createItem;
  updateProject = ProjectClient.prototype.updateProject;
  sortProjectItem = ProjectClient.prototype.sortProjectItem;
  generatePrd = ProjectClient.prototype.generatePrd;
  deletePrd = ProjectClient.prototype.deletePrd;
  updatePrd = ProjectClient.prototype.updatePrd;
  /* Project Categories */
  listProjectCategories = ProjectCategoryClient.prototype.listProjectCategories;
  getProjectCategory = ProjectCategoryClient.prototype.getCategory;
  createProjectCategory = ProjectCategoryClient.prototype.createCategory;
  updateProjectCategory = ProjectCategoryClient.prototype.updateCategory;
  deleteProjectCategory = ProjectCategoryClient.prototype.deleteCategory;
  sortProjectCategories = ProjectCategoryClient.prototype.sortCategories;
  getProjectCategoryItems = ProjectCategoryClient.prototype.getCategoryProjects;
  updateProjectCategoryItems = ProjectCategoryClient.prototype.updateCategoryProjects;
  sortProjectCategoryItems = ProjectCategoryClient.prototype.sortCategoryProjects;
  /* Trends */
  getTrendsGraph = TrendsClient.prototype.getTrendsGraph;
  getTrendsComments = TrendsClient.prototype.getTrendsComments;
  /* User Stories */
  listUserStories = UserStoryClient.prototype.listUserStories;
  getUserStory = UserStoryClient.prototype.getUserStory;
  createUserStory = UserStoryClient.prototype.createUserStory;
  updateUserStory = UserStoryClient.prototype.updateUserStory;
  deleteUserStory = UserStoryClient.prototype.deleteUserStory;
  listUserStoryExports = UserStoryClient.prototype.listUserStoryExports;
  createUserStoryExport = UserStoryClient.prototype.createUserStoryExport;
  generateUserStory = UserStoryClient.prototype.generateUserStory;
  bulkGenerateUserStory = UserStoryClient.prototype.bulkGenerateUserStory;
  getUserStoryStatuses = UserStoryClient.prototype.getUserStoryStatuses;
  createProjectUserStory = UserStoryClient.prototype.createProjectUserStory;
  createUserStoryAssignment = UserStoryClient.prototype.createUserStoryAssignment;
  getUserStorySortOrder = UserStoryClient.prototype.getUserStorySortOrder;
  sortProjectUserStories = UserStoryClient.prototype.sortProjectUserStories;
  assignUserStoriesToProject = UserStoryClient.prototype.assignUserStoriesToProject;
  /* Chat */
  askQuestion = ChatClient.prototype.askQuestion;
  listMessages = ChatClient.prototype.listMessages;
  listChats = ChatClient.prototype.listChats;
  getNewChat = ChatClient.prototype.getNewChat;
  createChat = ChatClient.prototype.createChat;
  createChatMessageNew = ChatClient.prototype.createChatMessageNew;
  /* Multi Source Feedback */
  listAnalysisItems = FeedbackClient.prototype.listAnalysisItems;
  getAnalysisItemsCount = FeedbackClient.prototype.getAnalysisItemsCount;
  getPaginatedAnalysisItems = FeedbackClient.prototype.getPaginatedAnalysisItems;
  getAnalysisDataByIds = FeedbackClient.prototype.getAnalysisDataByIds;
  /* Sources */
  listSources = SourceClient.prototype.listSources;
  getSourceById = SourceClient.prototype.getSourceById;
  createSource = SourceClient.prototype.createSource;
  updateSource = SourceClient.prototype.updateSource;
  deleteSource = SourceClient.prototype.deleteSource;
  /* Analysis Items */
  getDeepDiveItems = AnalysisItemClient.prototype.getDeepDiveItems;
}

export type FeedbackAnalysisItemLite = {
  id: string;
  type: FeedbackAnalysisItemType;
  rank: number;
  text: string;
  numberOfThreads: number;
  threads: { extra: Record<string, unknown> }[];
  mentions: number;
  reviewsCount: number;
  updatedText: string;
  deletedAt: Date;
  sortOrder: number;
  userStories: Array<UserStory>;
  projects: Omit<Project, "description" | "sort">[];
};

export enum FeedbackAnalysisItemType {
  complaint = "complaint",
  request = "request",
  lovedFeature = "lovedFeature",
  competitor = "competitor",
  summary = "summary",
  surveyTextAnswerAnalysis = "surveyTextAnswerAnalysis",
  deepDiveComplaint = "deepDiveComplaint",
  deepDiveRequest = "deepDiveRequest",
  deepDiveLovedFeature = "deepDiveLovedFeature",
  deepDiveCompetitor = "deepDiveCompetitor",
}

export type GetCommentsAnchoredToItemRequest = {
  orgSlug: string;
  anchorCommentId: string;
  page?: number;
};
